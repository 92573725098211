<template>
  <div class="flex-col page">
    <div class="page-7 flex-row j-space-around">
      <div class="page-left1 flex-col align-center">
        <i></i>
        <!-- <h2>ADTURING</h2> -->
        <p>为流量创造更多价值</p>
      </div>
      <div class="page-left2 flex-col ">
        <h3>解决方案</h3>
        <p @click.stop="$router.push('high')">高频广告</p>
        <p @click.stop="$router.push('fine')">精细化运营</p>
      </div>
      <div class="page-left3 flex-col ">
        <h3>ADTURING</h3>
        <p @click.stop="$router.push('contact')">联系我们</p>
      </div>
      <!-- <div class="page-left4 flex-col  ">
        <div class="flex-row">
          <h3 class="left-width">
            联系方式
          </h3>
          <p></p>
        </div>
        <div class="flex-row left4-p">
          <p class="left-width m0">商务合作</p>
          <p class="m0">XXX@seekshine.com</p>
        </div>
        <div class="flex-row align-start">
          <p class="left-width wx-text">微信</p>
          <i class="wx-box"></i>
        </div>
      </div> -->
      <!-- <div class="flex-col page-left5">
        <div
          class="cur flex-row flex-center register-but"
          @click.stop="LogInClick('register')"
        >
          注册
        </div>
        <div
          class="cur flex-row flex-center login-but"
          @click.stop="LogInClick('login')"
        >
          登录
        </div>
      </div> -->
    </div>
    <span
      >© 2021 All Rights Reserved. ADTURING
      <a href="https://beian.miit.gov.cn" target="_blank">
        闽ICP备2021011957号-1
      </a></span
    >
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import LogInClick from "../use/LogInClick";

export default defineComponent({
  name: "Footer",
  setup() {
    return {
      LogInClick,
    };
  },
});
</script>

<style lang="scss" scoped>
.page {
  height: 31.25vw;
  background: linear-gradient(181deg, #142d67 0%, #000000 100%);
  .page-7 {
    height: 100%;
    color: #fff;
    padding: 3vw 5.63vw 0;
    h3 {
      height: 1.719vw;
      font-size: 1.25vw;
      line-height: 1.719vw;
    }
    p {
      height: 1.146vw;
      font-size: 0.833vw;
      color: #ffffff;
      line-height: 1.146vw;
    }
    .page-left1 {
      i {
        width: 15.625vw;
        height: 1.615vw;
        background-image: url(~@/assets/image/logo_b.png);
        margin-top: 2.4vw;
        margin-bottom: 2.5vw;
        background-size: 100%;
      }
      h2 {
        height: 3.49vw;
        font-size: 2.5vw;
        color: #ffffff;
        line-height: 3.49vw;
        margin: 2vw 0 1.458vw;
      }
    }
    .page-left2 {
      padding-top: 1vw;
      p:first-of-type {
        margin: 2.708vw 0;
      }
    }
    .page-left3 {
      padding-top: 1vw;
      p {
        margin: 2.708vw 0;
      }
    }
    .page-left4 {
      padding-top: 1vw;
      .left-width {
        width: 5.208vw;
        text-align: center;
      }
      .left4-p {
        margin: 2.708vw 0;
      }
      .wx-box {
        width: 7.8vw;
        height: 7.8vw;
        margin-top: 1vw;
        // background-color: khaki;
        background-image: url(~@/assets/image/th/ewm.png);
        background-repeat: no-repeat;
        background-size: 100% 100%;
      }
    }
    .page-left5 {
      padding-top: 5vw;
      div {
        width: 10.104vw;
        height: 2.604vw;
        border-radius: 0.313vw;
        font-size: 0.833vw;
      }
      .register-but {
        border: 0.052vw solid #5ca4f1;
      }
      .login-but {
        margin-top: 2.5vw;
        background: #5ca4f1;
        box-shadow: 0vw 1.042vw 1.042vw -0.938vw #5ca4f1,
          0.052vw 0.052vw 0vw 0vw #a1ceff, -0.052vw -0.052vw 0vw 0vw #3c8add;
      }
    }
  }
  & > span {
    text-align: center;
    margin-bottom: 2vw;
    color: rgba(255, 255, 255, 0.5);
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    letter-spacing: 1px;
    a {
      color: rgba(255, 255, 255, 0.68);
    }
  }
}
</style>
