
import { createRouter, createWebHashHistory } from "vue-router";

// home
// solution
// news
// contact
const routes = [
	{
		path: "/",
		redirect: "/home",
  },
 
  {
    path: '/home',
    name:"Home",
		component:()=>import('@/views/Home.vue'),
  },
  {
    path: '/high',
    name:"High",
		component:()=>import('@/views/High.vue'),
  },
  {
    path: '/fine',
    name:"Fine",
		component:()=>import('@/views/Fine.vue'),
  },
  {
    path: '/news',
    name:"News",
		component:()=>import('@/views/News.vue'),
  }, {
    path: '/contact',
    name:"Contact",
		component:()=>import('@/views/Contact.vue'),
  }
]
export default createRouter({
  routes,
  history: createWebHashHistory(),
})