<template>
  <div
    class="flex-row menu-parent w-100 align-center p-r"
    :class="{ code_menu: codeMenu, logo_box }"
  >
    <i class="mx-4 logo"></i>
    <div class="flex-row p-r menu-box justify-end">
      <div
        class="flex-row flex-center link p-r"
        v-for="data in menus"
        :key="data.id"
        :class="[data.child ? 'cur-none' : 'cur']"
        @click.stop="routeFun(data.child, data.id)"
      >
        <span
          class="p-r flex-row align-center"
          :class="{
            'menu-link': type == data.id || someFun(data),
          }"
        >
          {{ data.name }}
        </span>
        <div v-if="data.child" class="p-a child-link flex-col flex-center px-3">
          <template v-for="(child, index) in data.child" :key="child.id">
            <hr
              v-if="index"
              class="m0 my-1 p0 w-100"
              style="border-width:1px 0 0 0"
            />
            <span
              class="flex-row flex-center cur p-r"
              :class="{ 'menu-link': type == child.id }"
              @click.stop="routeFun(false, child.id)"
            >
              {{ child.name }}
            </span>
          </template>
        </div>
      </div>
      <!-- <div
        class="flex-row flex-center cur p-r login"
        @click.stop="LogInClick('login')"
      >
        登录
      </div>
      <div
        class="flex-row flex-center cur p-r register"
        @click.stop="LogInClick('register')"
      >
        注册
      </div> -->
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, ref, watch } from "vue";
import { useRouter } from "vue-router";
import LogInClick from "@/use/LogInClick";

export default defineComponent({
  props: ["bool", "codeMenu"],
  setup() {
    const menus = [
      { id: "home", name: "首页" },
      {
        id: "solution",
        name: "解决方案",
        child: [
          { id: "high", name: "高频次广告" },
          { id: "fine", name: "精细化运营" },
        ],
      },
      // { id: "news", name: "行业动态" },
      { id: "contact", name: "联系我们" },
    ];
    const type = ref("home");
    const router = useRouter();
    function routeFun(bool: boolean, id: string) {
      if (!bool) {
        router.push("/" + id);
      }
    }

    function typeEach(
      menus: { id: string; name: string; child?: any[] }[],
      path: string
    ): string {
      for (let i = 0, l = menus.length; i < l; i++) {
        const ch = menus[i];
        if (ch.child) {
          const typ = typeEach(ch.child, path);
          if (typ) {
            return typ;
          }
        } else {
          if (path.indexOf(ch.id) > -1) {
            return ch.id;
          }
        }
      }
      return "";
    }
    const logo_box = computed(() => type.value == "fine");
    watch(router.currentRoute, (newRoute) => {
      type.value = typeEach(menus, newRoute.path) || "home";
    });

    function someFun(data: any) {
      return data.child?.some((x: any) => x.id == type.value) || false;
    }
    return {
      menus,
      type,
      routeFun,
      LogInClick,
      logo_box,
      someFun,
    };
  },
});
</script>

<style lang="scss" scoped>
.menu-link {
  &::before {
    content: "";
    position: absolute;
    left: -1vw;
    width: 0.417vw;
    height: 0.417vw;
    background-color: rgb(255, 255, 255);
    border-radius: 0.521vw;
    margin-right: 0.6vw;
  }
}
.menu-parent {
  font-size: 1.042vw;
  .logo {
    width: 12.917vw;
    height: 1.354vw;
    background-image: url(~@/assets/image/logo2.png);
    background-size: 100% 100%;
    z-index: 99;
  }

  z-index: 91;
  background-color: rgba(92, 106, 122, 0.702);
  &.logo_box {
    background-color: rgba(222, 236, 252, 0.702);
    .menu-box {
      color: #000;
      .menu-link::before {
        background-color: #000;
      }
      .child-link {
        background: linear-gradient(134deg, #e2e2e2 0%, #d7dce2 100%);
        box-shadow: 0.521vw 0.521vw 0.521vw 0vw rgba(209, 213, 230, 0.4);
      }
    }
    // text-shadow: rgb(129, 129, 129) 0px 0 10px;
    .logo {
      background-image: url(~@/assets/image/logo1.png);
    }
  }
  &.code_menu {
    background-color: transparent;
  }
  .menu-box {
    // background-color: rgba(30, 30, 30, 0.1);
    color: #fff;
    // text-shadow: rgb(167, 154, 154) 0px 0 10px;
    font-weight: 500;
    padding-right: 80px;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 99;
    height: 5.208vw;

    .link {
      width: 8vw;
    }
    .child-link {
      top: 80%;
      display: none !important;
      width: 9.375vw;
      height: 5.208vw;
      background: linear-gradient(134deg, #3c5a8e 0%, #1f3a61 100%);
      box-shadow: 0.521vw 0.521vw 0.521vw 0vw rgba(14, 36, 65, 0.3);
      border-radius: 0.313vw;
      span {
        height: 40%;
      }
    }
    .cur-none:hover {
      .child-link {
        display: flex !important;
      }
    }
    .register {
      width: 12.5vw;
      &::before {
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        left: 100%;
        top: 0;
        transition: left 100ms;
        background-color: rgba(91, 162, 238, 0.4);
        z-index: -2;
        left: 0;
      }
      overflow: hidden;
      // &:hover::before {
      // }
    }
    .login {
      width: 7.292vw;
      &::before {
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        left: 100%;
        top: 0;
        transition: left 100ms;
        background-color: rgba(91, 162, 238, 0.2);
        z-index: -2;
        left: 0;
      }
      overflow: hidden;
      // &:hover::before {
      // }
    }
  }
}
</style>
