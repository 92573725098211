<template>
  <div class="p-r start-box">
    <!-- v-show="codeMenu" -->
    <Menu :code-menu="codeMenu" />
    <div @scroll="scrollFun" ref="mDom" class="p-a overy router-box">
      <router-view />
      <Footer />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, watch } from "vue";
import Menu from "@/page/Menu.vue";
import Footer from "./page/Footer.vue";
import { useRouter } from "vue-router";
export default defineComponent({
  components: { Menu, Footer },
  setup() {
    const mDom = ref<HTMLDivElement>();
    const codeMenu = ref(true);
    const router = useRouter();
    console.log(router.currentRoute.value);

    watch(router.currentRoute, () => {
      if (mDom.value) {
        mDom.value.scrollTop = 0;
        codeMenu.value = true;
      }
    });

    function scrollFun() {
      if (mDom.value) {
        codeMenu.value = mDom.value.scrollTop < 100;
      }
    }

    return {
      mDom,
      codeMenu,
      scrollFun,
    };
  },
});
</script>

<style lang="scss">
@import "~@/scss/flex.scss";
body {
  padding: 0;
  margin: 0;
}
//隐藏按钮
.bottom-box {
  display: none !important;
  &.lianx_show {
    display: flex !important;
  }
}

.start-box {
  width: 100%; ///;
  height: 100vh;

  .router-box {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }
}
</style>
