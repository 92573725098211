
import { computed, defineComponent, ref, watch } from "vue";
import { useRouter } from "vue-router";
import LogInClick from "@/use/LogInClick";

export default defineComponent({
  props: ["bool", "codeMenu"],
  setup() {
    const menus = [
      { id: "home", name: "首页" },
      {
        id: "solution",
        name: "解决方案",
        child: [
          { id: "high", name: "高频次广告" },
          { id: "fine", name: "精细化运营" },
        ],
      },
      // { id: "news", name: "行业动态" },
      { id: "contact", name: "联系我们" },
    ];
    const type = ref("home");
    const router = useRouter();
    function routeFun(bool: boolean, id: string) {
      if (!bool) {
        router.push("/" + id);
      }
    }

    function typeEach(
      menus: { id: string; name: string; child?: any[] }[],
      path: string
    ): string {
      for (let i = 0, l = menus.length; i < l; i++) {
        const ch = menus[i];
        if (ch.child) {
          const typ = typeEach(ch.child, path);
          if (typ) {
            return typ;
          }
        } else {
          if (path.indexOf(ch.id) > -1) {
            return ch.id;
          }
        }
      }
      return "";
    }
    const logo_box = computed(() => type.value == "fine");
    watch(router.currentRoute, (newRoute) => {
      type.value = typeEach(menus, newRoute.path) || "home";
    });

    function someFun(data: any) {
      return data.child?.some((x: any) => x.id == type.value) || false;
    }
    return {
      menus,
      type,
      routeFun,
      LogInClick,
      logo_box,
      someFun,
    };
  },
});
