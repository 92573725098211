
import { defineComponent } from "vue";
import LogInClick from "../use/LogInClick";

export default defineComponent({
  name: "Footer",
  setup() {
    return {
      LogInClick,
    };
  },
});
