
import { defineComponent, ref, watch } from "vue";
import Menu from "@/page/Menu.vue";
import Footer from "./page/Footer.vue";
import { useRouter } from "vue-router";
export default defineComponent({
  components: { Menu, Footer },
  setup() {
    const mDom = ref<HTMLDivElement>();
    const codeMenu = ref(true);
    const router = useRouter();
    console.log(router.currentRoute.value);

    watch(router.currentRoute, () => {
      if (mDom.value) {
        mDom.value.scrollTop = 0;
        codeMenu.value = true;
      }
    });

    function scrollFun() {
      if (mDom.value) {
        codeMenu.value = mDom.value.scrollTop < 100;
      }
    }

    return {
      mDom,
      codeMenu,
      scrollFun,
    };
  },
});
